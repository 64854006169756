import React, { useState, useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'

// ** Props
import { ChatBotMessageBubbleProps } from './ChatBotMessageBubbleProps'

// ** Icons
import ChizlIcon from '@coreComponents/ChizlIcon'
import IconButton from '@coreComponents/IconButton'
import { BsCopy } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { RiThumbDownLine, RiThumbUpLine, RiThumbDownFill, RiThumbUpFill, RiExternalLinkLine } from 'react-icons/ri'

// ** API
import { _sendChatBotMessageFeedback } from '@api/chatbot'
import toast from 'react-hot-toast'
import copyClipboard from 'core/helpers/general/copyClipboard'
import { useAppSelector } from '@redux/store'
import { S3BaseURL } from 'core/constants'

// Define types
type PoorAnswerType = 'numerical' | 'logical' | 'response_format' | 'incomplete_answer' | 'other'
type FeedbackType = 'Poor Answer' | 'Wrong Document'

// Helper function to parse page numbers
const parsePageNumbers = (input: string): number[] => {
  const pages: number[] = []
  const parts = input.split(',')

  parts.forEach(part => {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number)
      for (let i = start; i <= end; i++) {
        pages.push(i)
      }
    } else {
      pages.push(Number(part))
    }
  })

  return pages
}

function ChatBotMessageBubble({ message, setIsTyping, ...rest }: ChatBotMessageBubbleProps) {
  const chatBotStore = useAppSelector(state => state.chatBot)
  const [typedMessage, setTypedMessage] = useState<string>('')
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const [feedbackType, setFeedbackType] = useState<FeedbackType | ''>('')
  const [poorAnswerType, setPoorAnswerType] = useState<PoorAnswerType | ''>('')
  const [additionalComments, setAdditionalComments] = useState<string>('')
  const [pageNumbers, setPageNumbers] = useState<string>('')
  const [thumbsDownSubmitted, setThumbsDownSubmitted] = useState<boolean>(false) // New state
  const [thumbsUpSubmitted, setThumbsUpSubmitted] = useState<boolean>(false) // New state

  const feedbackRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const typeMessage = () => {
      const characters = message.query.split('')
      characters.forEach((char, index) => {
        setTimeout(() => {
          setTypedMessage(prev => prev + char)
          if (index === characters.length - 1) {
            setIsTyping && setIsTyping(false)
          }
        }, 15 * index)
      })
    }

    if (message.sender === 'bot' && message.typed) {
      typeMessage()
    } else {
      setTypedMessage(message.query)
      setIsTyping && setIsTyping(false)
    }

    return () => {
      setTypedMessage('')
    }
  }, [message, setIsTyping])

  useEffect(() => {
    if (showFeedback && feedbackRef.current) {
      feedbackRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showFeedback])

  const handleSubmitFeedback = () => {
    const correctedPages = parsePageNumbers(pageNumbers)

    const feedback_details: any = {
      feedback_type: feedbackType === 'Poor Answer' ? 'poor_answer' : 'wrong_document',
      retrieved_pages: [], // Always send retrieved_pages as an empty array
      corrected_pages: feedbackType === 'Wrong Document' ? correctedPages : undefined,
      comments: additionalComments
    }

    if (feedbackType === 'Poor Answer') {
      feedback_details.poor_answer_type = poorAnswerType
    }

    _sendChatBotMessageFeedback(
      {
        chat_message_id: message.id ?? '',
        thumbs_down: true,
        feedback_details: {
          ...feedback_details,
          table:
            chatBotStore.selectedDocuments && chatBotStore.selectedDocuments[0].is_private
              ? 'private_document'
              : 'public_document'
        }
      },
      () => {
        setThumbsUpSubmitted(false)
        toast.success('Feedback submitted')
        // Reset states after successful feedback submission
        setFeedbackType('')
        setPoorAnswerType('')
        setPageNumbers('')
        setAdditionalComments('')
        setShowFeedback(false)
        setThumbsDownSubmitted(true) // Set thumbsDownSubmitted to true
      },
      error => {
        console.error('Error submitting feedback:', error)
      }
    )
  }

  const handleFeedbackTypeChange = (type: FeedbackType) => {
    setFeedbackType(type)
    setPoorAnswerType('') // Reset poorAnswerType
    setPageNumbers('') // Reset pageNumbers
  }

  const submitPositiveFeedback = () => {
    setThumbsUpSubmitted(true) // Set thumbsUpSubmitted to true
    setThumbsDownSubmitted(false)
  }

  return (
    <div>
      <div
        id={String(message.id)}
        className={`mb-[10px] ${message.sender === 'bot' && 'bg-light-black'} rounded-[20px] px-[10px] py-[15px] max-w-[500px]`}
        style={{
          marginLeft: message.sender === 'bot' ? 'auto' : ''
        }}
      >
        <div className='flex flex-row items-start gap-[12px]'>
          {message.sender === 'bot' ? (
            <div className='w-[40px] h-[40px] min-w-[40px] min-h-[40px] flex-center rounded-full p-[5px]'>
              <ChizlIcon icon='chizl-outline-white' className='' />
            </div>
          ) : (
            <img
              alt='user-profile'
              className='rounded-full w-[40px] h-[40px] object p-[1px]'
              style={{ objectFit: 'contain' }}
              src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
            />
          )}
          <div className='text-sm overflow-hidden'>
            <ReactMarkdown className='react_markdown'>{typedMessage}</ReactMarkdown>

            {message.highlighted_text &&
              message.highlighted_text.map(item => (
                <button
                  className='bg-chizl-black rounded-md flex items-center w-full overflow-hidden my-[7px] py-[6px] px-[11px] relative hover:bg-gray-900 hover:bg-opacity-40 transition-colors'
                  onClick={() => {
                    if (!message.highlighted_text) return
                    const event = new CustomEvent('highlight', {
                      detail: {
                        text: item.text,
                        page: item.page
                      }
                    })
                    window.dispatchEvent(event)
                  }}
                >
                  <div className='absolute w-[3px] top-0 bottom-0 bg-secondary-blue' style={{ left: 0 }} />
                  <span className='text-ellipsis text-truncate text-sm'>{item.text[0]}</span>
                </button>
              ))}
          </div>
        </div>

        {message.plot_paths_to_s3?.length && message.plot_paths_to_s3?.[0] !== null ? (
          <div className='relative group'>
            <button
              onClick={() => {
                if (message.plot_paths_to_s3) {
                  const url = `${S3BaseURL}/${message.plot_paths_to_s3[0]}`
                  window.open(url, '_blank', 'noopener,noreferrer')
                }
              }}
              className='group-hover:opacity-80 transition-opacity opacity-0 absolute bottom-1 right-1 bg-chizl-black p-1 rounded-md'
            >
              <RiExternalLinkLine />
            </button>
            <img className='rounded-md my-[20px]' src={`${S3BaseURL}/${message.plot_paths_to_s3[0]}`} />
          </div>
        ) : (
          <></>
        )}

        {/* Chatbot Bubble Footer */}
        {message.sender === 'bot' && (
          <div className='flex items-center gap-[3px] justify-end'>
            <IconButton onClick={submitPositiveFeedback}>
              {thumbsUpSubmitted ? <RiThumbUpFill size={18} /> : <RiThumbUpLine size={18} />}
            </IconButton>
            <IconButton style={{ marginBottom: -2 }} onClick={() => setShowFeedback(true)}>
              {thumbsDownSubmitted ? <RiThumbDownFill size={18} /> : <RiThumbDownLine size={18} />}
            </IconButton>
            <IconButton onClick={() => copyClipboard(message.query)}>
              <BsCopy strokeWidth='0.7px' />
            </IconButton>
          </div>
        )}
      </div>

      {/* Feedback Box */}
      {showFeedback && (
        <div ref={feedbackRef} className='rounded-lg border-chizl-black border mt-2 p-4'>
          <div className='flex justify-between items-center mt-[-6px]'>
            <h4 className='font-semibold text-sm'>Tell us more:</h4>
            <IconButton onClick={() => setShowFeedback(false)}>
              <IoClose />
            </IconButton>
          </div>
          <div className='flex gap-2 mt-2'>
            <button
              className={`px-3 py-1 border text-sm bg-opacity-25 border-chizl-black rounded ${feedbackType === 'Poor Answer' ? 'bg-gray-300' : ''}`}
              onClick={() => handleFeedbackTypeChange('Poor Answer')}
            >
              Poor Answer
            </button>
            <button
              className={`px-3 py-1 border bg-opacity-25 border-chizl-black text-sm rounded ${feedbackType === 'Wrong Document' ? 'bg-gray-300' : ''}`}
              onClick={() => handleFeedbackTypeChange('Wrong Document')}
            >
              Wrong Document
            </button>
          </div>
          {feedbackType === 'Poor Answer' && (
            <div className='flex gap-2 mt-4 flex-wrap'>
              <button
                className={`px-2 py-1 border text-xs bg-opacity-25 border-accent-orange border-opacity-60 rounded ${poorAnswerType === 'numerical' ? 'bg-accent-orange' : ''}`}
                onClick={() => setPoorAnswerType('numerical')}
              >
                Numerical
              </button>
              <button
                className={`px-2 py-1 border text-xs bg-opacity-25 border-accent-orange border-opacity-60 rounded ${poorAnswerType === 'logical' ? 'bg-accent-orange' : ''}`}
                onClick={() => setPoorAnswerType('logical')}
              >
                Logical
              </button>
              <button
                className={`px-2 py-1 border text-xs bg-opacity-25 border-accent-orange border-opacity-60 rounded ${poorAnswerType === 'response_format' ? 'bg-accent-orange' : ''}`}
                onClick={() => setPoorAnswerType('response_format')}
              >
                Response Format
              </button>
              <button
                className={`px-2 py-1 border text-xs bg-opacity-25 border-accent-orange border-opacity-60 rounded ${poorAnswerType === 'incomplete_answer' ? 'bg-accent-orange' : ''}`}
                onClick={() => setPoorAnswerType('incomplete_answer')}
              >
                Incomplete Answer
              </button>
              <button
                className={`px-2 py-1 border text-xs bg-opacity-25 border-accent-orange border-opacity-60 rounded ${poorAnswerType === 'other' ? 'bg-accent-orange' : ''}`}
                onClick={() => setPoorAnswerType('other')}
              >
                Other
              </button>
            </div>
          )}
          {feedbackType === 'Wrong Document' && (
            <div className='flex flex-col mt-4'>
              <label htmlFor='pageNumbers' className='text-xs mb-[-5px]'>
                Corrected Pages
              </label>
              <input
                type='text'
                placeholder='(ex: 1 or 3-5 or 3,6,8)'
                value={pageNumbers}
                onChange={e => setPageNumbers(e.target.value)}
                className='w-full mt-2 text-sm bg-transparent p-2 border rounded border-chizl-black'
              />
            </div>
          )}
          <div className='flex flex-col mt-4'>
            <label htmlFor='pageNumbers' className='text-xs mb-[-5px]'>
              Additional Comments
            </label>
            <textarea
              className='w-full mt-2 text-sm bg-transparent p-2 border rounded border-chizl-black'
              rows={3}
              placeholder='Share your thoughts...'
              value={additionalComments}
              onChange={e => setAdditionalComments(e.target.value)}
            />
          </div>
          <div className='flex justify-end mt-4'>
            <button onClick={handleSubmitFeedback} className='bg-accent-orange bg-opacity-70 text-sm px-3 py-1 rounded'>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatBotMessageBubble
