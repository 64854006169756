import React, { useEffect, useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion'

// ** Props
export interface sectionContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  /**
   * The class name of the outer container of the component
   */
  outerContainerClassName?: string
}

function SectionContainer({ children, outerContainerClassName, ...rest }: sectionContainerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref, { once: true }) // Trigger animation once
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (inView) {
      setIsVisible(true)
    }
  }, [inView])

  return (
    <div {...rest} className={`flex-center ${outerContainerClassName}`} ref={ref}>
      <motion.div
        className={`section-container p-[20px] ${rest.className}`}
        initial={{ opacity: 0, y: 20 }} // Start with opacity 0 and 20px down
        animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }} // Move up to original position
        transition={{ duration: 1 }} // Duration of the fade-in effect
      >
        {children}
      </motion.div>
    </div>
  )
}

export default SectionContainer
