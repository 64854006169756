// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// ** Interfaces Imports
import { MutliReportDocumentStatus, ResearchReportsDocumentInterface } from '@api/research_reports'

export interface ResearchReportsState {
  fetchLoading: boolean
  researchReports?: ResearchReportsDocumentInterface[]
  selectedDocument?: ResearchReportsDocumentInterface
  selectedDocuments: ResearchReportsDocumentInterface[]
  searchValue?: string
  selectedDocumentPrivacyFilter: 'All' | 'Private' | 'Public'
  statuses: { id: string; status: MutliReportDocumentStatus }[]
}

const initialState: ResearchReportsState = {
  fetchLoading: false,
  researchReports: undefined,
  selectedDocument: undefined,
  selectedDocuments: [],
  searchValue: undefined,
  statuses: [],
  selectedDocumentPrivacyFilter: 'All'
}

export const researchReportsSlice = createSlice({
  name: 'researchReports',
  initialState,
  reducers: {
    /**
     * Set the selected document
     */
    setSelectedResearchReport: (state, action: PayloadAction<ResearchReportsDocumentInterface>) => {
      state.selectedDocument = action.payload
    },

    /**
     * Clear the selected document
     */
    clearSelectedDocument: state => {
      state.selectedDocument = undefined
    },

    /**
     * Set the Research Report documents
     */
    setResearchReports: (state, action: PayloadAction<ResearchReportsDocumentInterface[]>) => {
      state.researchReports = action.payload
    },

    /**
     * Set the Research Report documents
     */
    removeResearchReport: (state, action: PayloadAction<string>) => {
      const idToRemove = action.payload // Get the id from the payload
      state.researchReports = state.researchReports?.filter(report => report.id !== idToRemove)
    },

    addSelectedDocuments: (state, action: PayloadAction<ResearchReportsDocumentInterface>) => {
      if (state.selectedDocuments.length === 3) return
      if (state.selectedDocuments && state.selectedDocuments?.length > 0) {
        state.selectedDocuments = [...state.selectedDocuments, action.payload]
      } else {
        state.selectedDocuments = [action.payload]
      }
    },

    removeSelectedDocumentById: (state, action: PayloadAction<string | number>) => {
      state.selectedDocuments = state.selectedDocuments?.filter(doc => doc.id !== action.payload)
    },

    removeAllSelectedDocuments: state => {
      state.selectedDocuments = []
    },

    /**
     * Clear the public filings data
     */
    clearResearchReports: state => {
      state.researchReports = undefined
    },

    setFetchLoading: (state, action: PayloadAction<boolean>) => {
      state.fetchLoading = action.payload
    },

    /**
     * Clear all Public Filings related data
     */
    clearState: state => {
      state = initialState
    },

    setSearchValue: (state, action: PayloadAction<string | undefined>) => {
      state.searchValue = action.payload
    },

    setSelectedDocumentPrivacyFilter: (state, action: PayloadAction<'All' | 'Private' | 'Public'>) => {
      state.selectedDocumentPrivacyFilter = action.payload
    },

    setStatuses: (state, action: PayloadAction<{ id: string; status: MutliReportDocumentStatus }[]>) => {
      state.statuses = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setResearchReports,
  setSelectedResearchReport,
  clearResearchReports,
  clearSelectedDocument,
  clearState,
  setFetchLoading,
  setSearchValue,
  setSelectedDocumentPrivacyFilter,
  setStatuses,
  removeResearchReport,
  addSelectedDocuments,
  removeSelectedDocumentById,
  removeAllSelectedDocuments
} = researchReportsSlice.actions

export default researchReportsSlice.reducer
