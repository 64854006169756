import React, { useEffect, useRef, useState } from 'react'
import UploadIcon from '@icons/UploadIcon'
import SearchIcon from '@icons/SearchIcon'
import Input from '@coreComponents/Input'
import Button from '@coreComponents/Button'
import Modal from '@coreComponents/Modal' // Ensure correct path to Modal
import { IoClose, IoInformationCircleOutline, IoSearch, IoTrashBinOutline } from 'react-icons/io5'
import {
  _getUserDocuments,
  _uploadPrivateResearchReportDocument,
  _uploadResearchReportDocument,
  _uploadUserDocument
} from '@api/research_reports'
import { useAppDispatch, useAppSelector } from '@redux/store'
import {
  removeAllSelectedDocuments,
  removeSelectedDocumentById,
  setFetchLoading,
  setResearchReports,
  setSearchValue,
  setSelectedDocumentPrivacyFilter
} from '@redux/researchReports/researchReportsSlice'
import { toast } from 'core/helpers/toast'

import Lottie from 'lottie-react'
import uploadAnimation from 'core/assets/lottie/uploading.json'
import { BsCheck, BsCheckLg, BsInfoCircle, BsTrash, BsTrash2, BsTrash3 } from 'react-icons/bs'
// Third-party Imports
import { useDropzone } from 'react-dropzone'
import FileIcon from '@icons/FileIcon'
import Chizl from '@icons/Chizl'
import { _createThread, _getThreads } from '@api/thread'
import { addSession, setSessions } from '@redux/sessions/sessionsSlice'
import { useNavigate } from 'react-router-dom'

type LanguageType = 'Ar' | 'En'

function Radio({ checked = false, onChange = () => {} }) {
  return (
    <div
      className='border-[1.6px] border-secondary-blue rounded-full w-[13px] h-[13px] p-[1px] flex-center'
      onClick={onChange}
    >
      {checked && <div className='w-full h-full bg-secondary-blue rounded-full' />}
    </div>
  )
}

function Checkbox({ checked = false, onChange = (e: any) => {} }) {
  return (
    <div
      className='border-[1.6px] border-secondary-blue rounded-[3px] w-[15px] h-[14px] flex-center'
      onClick={onChange}
    >
      {checked && <BsCheckLg className='text-white' size={12} strokeWidth={0.4} />}
    </div>
  )
}

function ResearchReportsHeader() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<LanguageType>('En')
  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const selectedPrivacyFilter = useAppSelector(state => state.researchReports.selectedDocumentPrivacyFilter)
  const { selectedDocuments } = useAppSelector(state => state.researchReports)
  const { sessions } = useAppSelector(state => state.sessions)

  const handleUploadClick = () => {
    setIsModalOpen(true)
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    setSelectedFile(file)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedFile(undefined) // Clear selected file when closing modal
  }

  const fetchDocuments = () => {
    dispatch(setFetchLoading(true))
    _getUserDocuments(
      documents => {
        dispatch(setResearchReports(documents))
        dispatch(setFetchLoading(false))
      },
      err => {
        dispatch(setFetchLoading(false))
      }
    )
  }

  const handleConfirmUpload = () => {
    if (selectedFile) {
      const uploadFunction = isPrivate ? _uploadPrivateResearchReportDocument : _uploadResearchReportDocument
      setUploading(true)
      _uploadUserDocument(
        { file: selectedFile, language, is_private: Boolean(isPrivate) },
        data => {
          console.log('Upload success:', data)
          setIsModalOpen(false)
          toast.success('Document uploaded')
          setSelectedFile(undefined) // Clear selected file after upload
          fetchDocuments()
          setUploading(false)
        },
        error => {
          setUploading(false)
          if (error.response?.data.detail) {
            toast.error(error.response.data.detail)
          }

          console.error('Upload error:', error)
        }
      )
    } else {
      console.error('No file selected.')
    }
  }

  const handleCreateThread = () => {
    _createThread(
      {
        doc_ids: selectedDocuments.map(doc => {
          return {
            id: doc.id ?? '',
            type: 'useruploaded'
          }
        })
      },
      res => {
        dispatch(removeAllSelectedDocuments())
        _getThreads(res => dispatch(setSessions(res)))
        navigate(`/dashboard/multi_reports/session/${res.thread.id}`)
      },
      err => {
        toast.error('Failed to created session.')
      }
    )
  }

  // ** File Dropzone
  type FileProp = {
    name: string
    type: string
    size: number
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles?.[0].size > 10000000) {
        toast.error('File size must be below 10MB')
      } else {
        setSelectedFile(Object.assign(acceptedFiles?.[0]))
      }
    }
  })

  return (
    <div className='mb-[10px]'>
      <div className='flex items-center h-fit gap-[20px] mb-[10px]'>
        <Input
          placeholder='Search documents'
          StartIcon={SearchIcon}
          boxClassName='border-none bg-chizl-black w-[100%] min-w-[330px]'
          boxStyle={{ borderRadius: '7px' }}
          EndIcon={<IoSearch />}
          onChange={e => {
            dispatch(setSearchValue(e.target.value))
          }}
        />

        <input ref={fileInputRef} accept='.pdf' type='file' className='hidden ml-auto' onChange={handleFileChange} />

        <button onClick={handleUploadClick}>
          <UploadIcon />
        </button>

        {selectedDocuments.length > 0 && (
          <button
            className='bg-[#282828] rounded-[7.5px] flex-center h-[27px] p-[6px] gap-[6px] w-[150px]'
            onClick={handleCreateThread}
          >
            <Chizl width={22} height={22} />
            <span className='text-truncate'>Open with Chizl</span>
          </button>
        )}

        {/* <Button
          endIcon={<UploadIcon />}
          className='whitespace-nowrap ml-auto h-[40px] text-black text-sm'
          onClick={handleUploadClick}
        >
          Upload Document
        </Button> */}

        {isModalOpen && (
          <Modal onClose={handleCloseModal}>
            <div className='p-4'>
              {uploading ? (
                <div>
                  <h1 className='font-semibold text-lg'>Uploading...</h1>
                  <Lottie animationData={uploadAnimation} className='h-[350px] mt-[-30px] mb-[-30px]' />
                  <div className='flex flex-row items-center gap-3'>
                    <BsInfoCircle size={15} className='text-gray-400' />
                    <span className='text-sm flex-1'>Your file is being uploaded, this may take a few seconds.</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className='flex items-center justify-between mb-[10px]'>
                    <h2 className='text-lg font-bold'>Upload Document</h2>
                    <button onClick={handleCloseModal}>
                      <IoClose color='white' />
                    </button>
                  </div>
                  {/* Language Selection */}
                  <div className='mb-[10px]'>
                    <label className='block text-sm font-medium text-white'>Language:</label>
                    <div className='flex items-center space-x-4'>
                      <label className='flex items-center space-x-2'>
                        <input
                          type='radio'
                          value='En'
                          checked={language === 'En'}
                          onChange={() => setLanguage('En')}
                          className='hidden'
                        />
                        <Radio checked={language === 'En'} onChange={() => setLanguage('En')} />
                        <span className='text-white'>English</span>
                      </label>
                      <label className='flex items-center space-x-2'>
                        <input
                          type='radio'
                          value='Ar'
                          checked={language === 'Ar'}
                          onChange={() => setLanguage('Ar')}
                          className='hidden'
                        />
                        <Radio checked={language === 'Ar'} onChange={() => setLanguage('Ar')} />
                        <span className='text-white'>عربي</span>
                      </label>
                    </div>
                  </div>
                  {/* Privacy Selection */}
                  <div className='mb-[10px]'>
                    <label className='block mb-2 text-sm font-medium text-white'>Document Type:</label>
                    <label className='flex items-center space-x-2'>
                      <input
                        type='checkbox'
                        checked={isPrivate}
                        onChange={e => setIsPrivate(e.target.checked)}
                        className='hidden'
                      />
                      <Checkbox checked={isPrivate} onChange={e => setIsPrivate(e.target.checked)} />
                      <span className='text-white'>Private Document</span>
                    </label>
                  </div>
                  <div className='flex items-center gap-[10px] my-[20px]'>
                    <IoInformationCircleOutline size={50} color='white' />
                    <span className='text-xs line-clamp-6'>
                      Documents and prompts that contain confidential information will not be used for training
                      purposes. The data is end-to-end encrypted to ensure your data is private and secured
                    </span>
                  </div>

                  {/* File Input */}
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    className='cursor-pointer border-gray-500 border-2 rounded-md p-[20px] border-dashed'
                  >
                    <input {...getInputProps()} />
                    <div className='flex items-center flex-col'>
                      <div className='flex-center bg-secondary-blue bg-opacity-20 rounded p-[10px]'>
                        <UploadIcon className='text-secondary-blue' width={20} height={20} />
                      </div>
                      <h3 className='mbe-2.5'>Drop files here or click to upload.</h3>
                      <span className='text-center text-sm font-light'>Allowed *.pdf, Max file size of 3MB</span>
                    </div>
                  </div>
                  {selectedFile && (
                    <div className='flex items-center gap-[10px] my-[15px] w-full border-[1px] rounded-md p-[5px] border-gray-400 border-opacity-30'>
                      <div className='flex-center bg-gray-500 bg-opacity-20 rounded p-[10px] w-[40px] h-[40px]'>
                        <FileIcon width={18} height={18} />
                      </div>
                      <div className='flex flex-col items-start w-[80%]'>
                        <span className='font-light text-truncate w-[100%] flex-1'>{selectedFile.name}</span>
                        <span className='font-light text-sm text-gray-400'>
                          {Math.round(selectedFile.size / 100) / 10 > 1000
                            ? `${(Math.round(selectedFile.size / 100) / 10000).toFixed(1)} mb`
                            : `${(Math.round(selectedFile.size / 100) / 10).toFixed(1)} kb`}
                        </span>
                      </div>

                      <button className='' onClick={() => setSelectedFile(undefined)}>
                        <BsTrash3 className='text-red-500' />
                      </button>
                    </div>
                  )}
                  {/* <div className='mb-[10px]'>
                  <label className='block mb-2 text-sm font-medium text-white'>Select File:</label>
                  <input
                    ref={fileInputRef}
                    accept='.pdf'
                    type='file'
                    className='border-2 border-gray-400 rounded p-2 w-full'
                    onChange={handleFileChange}
                  />
                </div> */}

                  {/* Upload Button */}
                  <Button onClick={handleConfirmUpload} className='mx-auto mt-[22px]' disabled={!selectedFile}>
                    Upload
                  </Button>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>

      <div className='flex items-center gap-[10px]'>
        {['All', 'Private', 'Public'].map(group => (
          <button
            className={` px-[13px] py-[2.5px] rounded-[30px] ${selectedPrivacyFilter === group ? 'bg-secondary-blue-primary bg-opacity-80' : 'bg-opacity-20 bg-light-blue'}`}
            onClick={() => dispatch(setSelectedDocumentPrivacyFilter(group as 'All' | 'Private' | 'Public'))}
          >
            {group}
          </button>
        ))}
      </div>

      <div className='flex items-center ml-auto pt-[10px] justify-between gap-[10px] flex-wrap'>
        <div className='flex gap-[15px]'>
          {selectedDocuments.length > 0 && (
            <div className='flex-center font-thin'>
              <button onClick={() => dispatch(removeAllSelectedDocuments())}>
                <IoClose />
              </button>
              {selectedDocuments.length} selected
            </div>
          )}
          <div className='flex items-center gap-[10px]'>
            {selectedDocuments.map(doc => (
              <div
                key={doc.id}
                className='relative text-sm bg-secondary-blue bg-opacity-30 text-primary-blue px-[8px] py-[2.5px] rounded-[5px]'
              >
                <button
                  className='bg-gray-300 rounded-full flex-center absolute top-[-5px] right-[-5px]'
                  onClick={() => dispatch(removeSelectedDocumentById(doc.id ?? ''))}
                >
                  <IoClose color='black' size={10} />
                </button>
                {doc.document_name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResearchReportsHeader
