// ** React Imports
import { Fragment } from 'react'

// ** Props Imports
import { buttonProps } from './buttonProps'

// ** React Router Imports
import { Link } from 'react-router-dom'

// ** Utils Imports
import { getTextColorClassName } from '../../helpers/tailwind/getTextColorClassName'
import { getBorderColorClassName } from '../../helpers/tailwind/getBorderColorClassName'
import { getBackgroundColorClassName } from '../../helpers/tailwind/getBackgroundColorClassName'
import Loader from '@coreComponents/Loader'

function Button({
  size = 'regular',
  variant = 'fill',
  startIcon,
  endIcon,
  color = 'primary-blue',
  href,
  disabled = false,
  loading = false,
  ...rest
}: buttonProps) {
  // ** Link tag
  const LinkTag = href ? Link : Fragment

  // ** Button Styling Params
  // -- Text Color --
  const textColor = getTextColorClassName(color)

  // -- Background Color --
  const backgroundColor = getBackgroundColorClassName(color)

  // -- Border Color --
  const borderColor = getBorderColorClassName(color)

  // ** Base ClassNames
  const baseClassName = `flex items-center justify-center transition-transform duration-150`

  // ** Variation ClassNames
  const fillClassName = `${backgroundColor} text-white ${borderColor} border-[2.5px]`
  const outlineClassName = `${textColor} bg-transparent ${borderColor} border-[2.5px]`

  // ** Size ClassNames
  const regularSizeClassName = 'px-[15.5px] py-[4px] rounded-[5px] gap-[5px] min-w-[120px]'
  const largeSizeClassName = 'text-lg rounded-[8px] pt-[11px] pb-[12px] px-[25px] gap-[8px] min-w-[100px]'
  const smallSizeClassName = 'text-sm rounded-[5px]'

  // ** State ClassNames
  const disabledClassName = `opacity-60 cursor-auto`
  const loadingClassName = ``

  // ** Active ClassName for shrink effect
  const activeClassName = !disabled ? 'active:scale-95' : ''

  return (
    <LinkTag to={href || ''}>
      <button
        {...rest}
        className={`${baseClassName} ${variant === 'fill' ? fillClassName : variant === 'outline' ? outlineClassName : ''} ${size === 'regular' ? regularSizeClassName : size === 'large' ? largeSizeClassName : size === 'small' && smallSizeClassName} ${disabled ? disabledClassName : ''} ${loading ? loadingClassName : ''} ${activeClassName} ${rest.className}`}
        disabled={disabled}
      >
        {startIcon && <span className={`${size === 'large' ? 'pt-[2px]' : ''}`}>{startIcon}</span>} {rest.children}{' '}
        {endIcon && <span className={`${size === 'large' ? 'pt-[2px]' : ''}`}>{endIcon}</span>}
        {loading && (
          <div className='mr-[-22px]'>
            <Loader />
          </div>
        )}
      </button>
    </LinkTag>
  )
}

// ** Display Name
Button.displayName = 'Button'

export default Button
