import React, { useState, useEffect } from 'react'
import { FaCheck } from 'react-icons/fa6'
import Sidebar from '@coreComponents/sidebar-components/Sidebar'
import Select, { components } from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '@redux/store'
import { setSelectedIndustries, setSelectedExchange } from '@redux/publicFilings/publicFilingsSlice'

interface Option {
  id: number
  label: string
  checked: boolean
  companyCount?: number
}

interface CustomCheckboxProps {
  option: Option
  onChange: (id: number) => void
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ option, onChange }) => {
  return (
    <label className='flex items-center cursor-pointer' onClick={() => onChange(option.id)}>
      <div
        className={`w-[12px] h-[12px] rounded-sm flex items-center justify-center ${
          option.checked ? 'bg-white' : 'border-[1px] border-white bg-transparent'
        }`}
      >
        {option.checked && <FaCheck size={9} className='text-black' />}
      </div>
      <span className='ml-2 text-white text-sm'>
        {option.label} {option.companyCount && `(${option.companyCount})`}
      </span>
    </label>
  )
}

const GulfFilingSidebar: React.FC = () => {
  const { region } = useParams<{ region: string }>()
  const [industryOptions, setIndustryOptions] = useState<Option[]>([])
  const [selectAll, setSelectAll] = useState(true)
  const selectedIndustries = useAppSelector(state => state.publicFilings.selectedIndustries)
  const selectedExchange = useAppSelector(state => state.publicFilings.selectedExchange)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const countries: { code: string; label: string }[] = [
    { label: 'USA', code: 'us' },
    { label: 'Saudi Arabia', code: 'sa' },
    { label: 'UAE', code: 'ae' },
    { label: 'Qatar', code: 'qa' },
    { label: 'Kuwait', code: 'kw' },
    { label: 'Bahrain', code: 'bh' }
  ]

  const exchanges = useAppSelector(state => state.publicFilings.exchanges)
  const industries = useAppSelector(state => state.publicFilings.gcc_filings)

  const defaultCountry = countries.find(country => country.code === region)

  useEffect(() => {
    const newIndustryOptions: Option[] = Object.keys(industries).map((industry, index) => ({
      id: index + 1,
      label: industry,
      checked: true,
      companyCount: Object.keys(industries[industry]).length
    }))

    setIndustryOptions(newIndustryOptions)
    dispatch(setSelectedIndustries(newIndustryOptions.map(option => option.label)))
  }, [industries, dispatch])

  const handleCheckboxChange = (id: number) => {
    setIndustryOptions(prevOptions =>
      prevOptions.map(option => {
        if (option.id === id) {
          const newOption = { ...option, checked: !option.checked }
          const updatedIndustries = newOption.checked
            ? [...selectedIndustries, newOption.label]
            : selectedIndustries.filter(industry => industry !== newOption.label)
          dispatch(setSelectedIndustries(updatedIndustries))
          return newOption
        }
        return option
      })
    )
  }

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll
    setSelectAll(newSelectAll)
    const updatedOptions = industryOptions.map(option => ({
      ...option,
      checked: newSelectAll
    }))
    setIndustryOptions(updatedOptions)
    dispatch(setSelectedIndustries(newSelectAll ? updatedOptions.map(option => option.label) : []))
  }

  const handleExchangeChange = (exchange: string) => {
    dispatch(setSelectedExchange(exchange))
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'gray',
      borderRadius: '8px'
    }),
    option: (provided: any, state: { isSelected: boolean }) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: 'black',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black'
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#151515'
    })
  }

  const OptionComponent = (props: any) => {
    return (
      <components.Option {...props}>
        <div className='flex text-white text-sm items-center'>
          <img
            src={`https://flagcdn.com/w160/${props.data.code}.png`}
            srcSet={`https://flagcdn.com/w320/${props.data.code}.png 2x`}
            width='20'
            alt={props.data.label}
            className='w-[31px] h-[18px] rounded-sm mr-2'
          />
          {props.data.label}
        </div>
      </components.Option>
    )
  }

  const SingleValueComponent = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <div className='flex text-white text-sm items-center'>
          <img
            src={`https://flagcdn.com/w160/${props.data.code}.png`}
            srcSet={`https://flagcdn.com/w320/${props.data.code}.png 2x`}
            width='20'
            alt={props.data.label}
            className='w-[31px] h-[18px] rounded-sm mr-2'
          />
          {props.data.label}
        </div>
      </components.SingleValue>
    )
  }

  const handleCountryChange = (country: { code: string }) => {
    navigate(`/dashboard/public_filings/${country?.code}`)
  }

  return (
    <Sidebar className='overflow-auto'>
      <Select
        isSearchable={false}
        className='basic-select'
        classNamePrefix='select'
        styles={customStyles}
        components={{ Option: OptionComponent, SingleValue: SingleValueComponent }}
        defaultValue={defaultCountry}
        onChange={country => handleCountryChange({ code: country?.code ?? '' })}
        options={countries}
      />

      {exchanges.length > 1 && (
        <div className='flex my-6 gap-5 justify-around'>
          {exchanges.map((exchange: any) => (
            <button
              key={exchange}
              className={`px-3 py-1 flex-1 text-xs whitespace-nowrap mr-2 border-b-[2px] ${
                selectedExchange === exchange ? 'border-b-primary-blue' : 'border-b-chizl-black'
              }`}
              onClick={() => handleExchangeChange(exchange)}
            >
              {exchange}
            </button>
          ))}
        </div>
      )}

      <div className='mt-[20px]'>
        <div className='flex items-center mb-4 pb-4 border-b-1 border-light-black border-b-[1px]'>
          <CustomCheckbox
            option={{ id: 0, label: 'Select All', checked: selectAll, companyCount: undefined }}
            onChange={handleSelectAllChange}
          />
        </div>
        <div className='flex flex-col space-y-2'>
          {industryOptions.map(option => {
            return <CustomCheckbox key={option.id} option={option} onChange={handleCheckboxChange} />
          })}
        </div>
      </div>
    </Sidebar>
  )
}

export default GulfFilingSidebar
