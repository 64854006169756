// ** Dashboard Routes
export const dashboardRoutes = [
  '/dashboard',
  '/dashboard/public_filings',
  '/dashboard/research_reports',
  '/dashboard/vdr',
  '/dashboard/transcriptions',
  '/landing/v2',
  '/'
]

export const authRoutes = ['/login', '/register']
