import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@redux/store'
import { setSelectedResearchReport } from '@redux/researchReports/researchReportsSlice'
import {
  _getMultiReportByID
  // _getPrivateResearchReportDocument,
  // _getResearchReportDocument
} from '@api/research_reports'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { Worker, Viewer, SpecialZoomLevel, Icon } from '@react-pdf-viewer/core'
import pdfjsVersion from 'pdfjs-dist/package.json'
import readingIndicatorPlugin from '@components/pdf-viewer/readingIndicatorPlugin'
import { ToolbarSlot, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { IoArrowBack } from 'react-icons/io5'
import { OnHighlightKeyword, RenderHighlightsProps, searchPlugin } from '@react-pdf-viewer/search'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import { S3BaseURL } from 'core/constants'
import { Thread } from '@api/thread'
import { API } from '@api/index'
import Loader from '@coreComponents/Loader'

function ViewSession() {
  const { sessionId } = useParams<{ sessionId: string }>()
  const navigate = useNavigate()

  const { researchReports } = useAppSelector(state => state.researchReports)

  const [session, setSession] = useState<Thread | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [docIdInView, setDocIdInView] = useState<string | undefined>()

  const fetchSession = () => {
    setLoading(true)
    API.get(`/thread/get_thread/${sessionId}`)
      .then(res => {
        const session: Thread = res.data
        setSession(session)
        setDocIdInView(session.documents?.[0].id)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchSession()
  }, [sessionId])

  const viewerRef = useRef<HTMLDivElement>(null)

  const renderSwitcher = () => {
    return (
      <>
        {session?.documents &&
          session.documents.map(doc => (
            <button
              onClick={() => setDocIdInView(doc.id)}
              key={doc.id}
              className={`${docIdInView === doc.id ? 'bg-opacity-70' : 'bg-opacity-50 opacity-70'} flex-1 text-truncate text-md bg-secondary-blue text-primary-blue px-[12px] py-[3px] rounded-[15px] max-w-[240px] text-center font-semibold`}
            >
              {doc.document_name}
            </button>
          ))}
      </>
    )
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar: any) => (
      <>
        <Toolbar>
          {(slots: ToolbarSlot) => {
            const {
              CurrentPageInput,
              Download,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              ShowSearchPopover,
              Zoom,
              ZoomIn,
              ZoomOut,
              ShowProperties
            } = slots

            return (
              <div className='flex items-center justify-between w-full pl-[11px]'>
                <button className='mb-[-2px] mr-[7px]' onClick={() => navigate(-1)}>
                  <IoArrowBack size={20} />
                </button>
                <div className='flex items-center gap-[5px]'>{renderSwitcher()}</div>

                <div className='flex items-center ml-auto'>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', display: 'flex', alignItems: 'center' }}>
                    <CurrentPageInput /> / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                </div>

                <div className='flex items-center ml-[30px]'>
                  <ZoomOut />
                  <Zoom />
                  <ZoomIn />
                </div>
              </div>
            )
          }}
        </Toolbar>
        <div
          style={{
            bottom: '-0.25rem',
            position: 'absolute',
            left: 0,
            width: '100%'
          }}
        >
          {readingIndicatorPlugin().ReadingIndicator()}
        </div>
      </>
    ),
    sidebarTabs: defaultTabs =>
      defaultTabs.concat({
        content: <div style={{ textAlign: 'center', width: '100%', color: 'white' }}>Notes are listed here</div>,
        icon: (
          <Icon size={16}>
            <path d='M23.5,17a1,1,0,0,1-1,1h-11l-4,4V18h-6a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z' />
            <path d='M5.5 12L18.5 12' />
            <path d='M5.5 7L18.5 7' />
          </Icon>
        ),
        title: 'Notes'
      })
  })

  const renderHighlights = React.useCallback(
    (renderProps: RenderHighlightsProps) => (
      <>
        {renderProps.highlightAreas.map((area, index) => (
          <div
            key={`${area.pageIndex}-${index}`}
            style={{
              ...renderProps.getCssProperties(area),
              position: 'absolute',
              backgroundColor: 'rgba(243, 245, 39, 0.35)'
            }}
          >
            {/* The content of highlighted element */}
          </div>
        ))}
      </>
    ),
    []
  )
  const searchPluginInstance = searchPlugin({
    // onHighlightKeyword: (props: OnHighlightKeyword) => {
    //   // props.highlightEle.style.outline = '2px dashed blue'
    //   // props.highlightEle.style.backgroundColor = 'rgba(243, 245, 39, 0.48)'
    // },
    renderHighlights
  })
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { jumpToPage } = pageNavigationPluginInstance

  const { highlight } = searchPluginInstance

  useEffect(() => {
    const handleHighlight = (event: CustomEvent) => {
      const { detail } = event
      if (highlight) {
        highlight(detail.text)
        jumpToPage(detail.page - 1)
      }
    }

    window.addEventListener('highlight', handleHighlight as EventListener)

    return () => {
      window.removeEventListener('highlight', handleHighlight as EventListener)
    }
  }, [])

  return (
    <div ref={viewerRef} style={{ width: '100%', height: '100%' }}>
      {loading ? (
        <div className='w-full h-full flex-center'>
          <Loader className='w-[40px] h-[40px]' />
        </div>
      ) : (
        session?.documents &&
        session.documents?.length > 0 && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion.version}/build/pdf.worker.min.js`}>
            <Viewer
              plugins={[
                defaultLayoutPluginInstance,
                readingIndicatorPlugin(),
                searchPluginInstance,
                pageNavigationPluginInstance
              ]}
              defaultScale={SpecialZoomLevel.PageWidth}
              // fileUrl={`${S3BaseURL}/${documents[0].path_to_s3}`}
              // fileUrl='https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf'
              fileUrl={`data:application/pdf;base64,${session.documents.find(doc => doc.id === docIdInView)?.base_encoding}`}
              theme='dark'
            />
          </Worker>
        )
      )}
    </div>
  )
}

export default ViewSession
