// ** React Imports
import React, { SVGProps } from 'react'

const GlobalPublicIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width='33' height='33' viewBox='0 0 33 33' fill='none' {...props}>
      <path
        d='M16.8756 32.1089C25.2891 32.1089 32.1091 25.2889 32.1091 16.8754C32.1091 8.46195 25.2891 1.64191 16.8756 1.64191C8.46213 1.64191 1.64209 8.46195 1.64209 16.8754C1.64209 25.2889 8.46213 32.1089 16.8756 32.1089Z'
        stroke={props.color ?? 'currentColor'}
        stroke-width='1.69261'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.64209 16.8754H32.1091'
        stroke={props.color ?? 'currentColor'}
        stroke-width='1.69261'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.8756 32.1089C20.303 32.1089 23.0818 25.2889 23.0818 16.8754C23.0818 8.46195 20.303 1.64191 16.8756 1.64191C13.4482 1.64191 10.6693 8.46195 10.6693 16.8754C10.6693 25.2889 13.4482 32.1089 16.8756 32.1089Z'
        stroke={props.color ?? 'currentColor'}
        stroke-width='1.69261'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.15564 6.15558C7.56189 7.59426 9.23285 8.7352 11.0725 9.51286C12.9122 10.2905 14.8843 10.6895 16.8755 10.687C18.8668 10.6895 20.8389 10.2905 22.6785 9.51286C24.5182 8.7352 26.1891 7.59426 27.5954 6.15558M27.5954 27.5953C26.1891 26.1566 24.5182 25.0157 22.6785 24.238C20.8389 23.4604 18.8668 23.0614 16.8755 23.0639C14.8843 23.0614 12.9122 23.4604 11.0725 24.238C9.23285 25.0157 7.56189 26.1566 6.15564 27.5953'
        stroke={props.color ?? 'currentColor'}
        stroke-width='1.69261'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default GlobalPublicIcon
