import React from 'react'

function ChizlGradient({ ...props }: React.ComponentProps<'svg'>) {
  return (
    <svg width='40' height='28' viewBox='0 0 40 28' fill='none' {...props}>
      <defs>
        <linearGradient id='gradient' x1='50%' y1='0%' x2='50%' y2='100%'>
          <stop offset='0%' stopColor='#A1A1A1' /> {/* Gray 500 */}
          <stop offset='100%' stopColor='#101011' /> {/* Gray 900 */}
        </linearGradient>
      </defs>
      {/* <path
        d='M6.52798 1.79517L2 9.62624L10.8296 25.8642M6.52798 1.79517H33.9223L38.6766 9.39592M6.52798 1.79517L11.3956 9.62624M38.6766 9.39592L30.4131 25.8642H10.8296M38.6766 9.39592L34.2848 18.1483H24.8663M38.6766 9.39592L29.6207 9.47237M10.8296 25.8642L15.8103 18.1483M15.8103 18.1483L11.3956 9.62624M15.8103 18.1483H24.8663M11.3956 9.62624L29.6207 9.47237M24.8663 18.1483L29.6207 9.47237'
        stroke={props.color ?? 'currentColor'}
        stroke-width='2.29229'
      /> */}
      <path
        d='M6.52798 1.79517L2 9.62624L10.8296 25.8642M6.52798 1.79517H33.9223L38.6766 9.39592M6.52798 1.79517L11.3956 9.62624M38.6766 9.39592L30.4131 25.8642H10.8296M38.6766 9.39592L34.2848 18.1483H24.8663M38.6766 9.39592L29.6207 9.47237M10.8296 25.8642L15.8103 18.1483M15.8103 18.1483L11.3956 9.62624M15.8103 18.1483H24.8663M11.3956 9.62624L29.6207 9.47237M24.8663 18.1483L29.6207 9.47237'
        stroke='url(#gradient)'
        strokeWidth='2.29229'
      />
    </svg>
  )
}

export default ChizlGradient
