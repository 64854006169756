// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

// ** Reducer Imports
import authReducer from '@redux/auth/authSlice'
import chatBotReducer from '@redux/chatBot/chatBotSlice'
import sessionsThread from '@redux/sessions/sessionsSlice'
import publicFilingsReducer from '@redux/publicFilings/publicFilingsSlice'
import researchReportsReducer from '@redux/researchReports/researchReportsSlice'

export const store = configureStore({
  reducer: {
    // -- User Authentication --
    auth: authReducer,

    publicFilings: publicFilingsReducer,
    researchReports: researchReportsReducer,
    chatBot: chatBotReducer,
    sessions: sessionsThread
  }
})

// ** Store Exports
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// ** Redux Hooks Exports
/**
 * Used instead of useDispatch and useSelector
 * for type checking and autocompletion
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
