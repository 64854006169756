// ** Axios Imports
import { AxiosError } from 'axios'

// ** API Imports
import { API } from '..'
import { ApiError } from 'core/types/api'
import { ChatBotMessageInterface } from '@api/chatbot'

type DocId = {
  id: number | string
  type: 'sec' | 'gcc' | 'useruploaded'
}
type CreateThreadRequest = {
  doc_ids: DocId[]
}

export interface Document {
  document_name: string
  extension: string
  id: string
  is_private: boolean
  language: 'En' | 'Ar'
  path_to_s3: string
  screenshot_s3_path: string
  status: string
  uploaded_at: string
  user_id: number
  base_encoding: string
}
export interface Thread {
  id: number
  messages: ChatBotMessageInterface[]
  title?: string
  documents: Document[]
}

export const _createThread = (
  requestBody: CreateThreadRequest,
  callback?: (res: { message: string; thread: Thread }) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.post('/thread/create', requestBody)
    .then(res => {
      callback && callback(res.data as { message: string; thread: Thread })
    })
    .catch(err => errCallback && errCallback(err))
}

export const _getThreads = (callback?: (res: Thread[]) => void, errCallback?: (error: AxiosError) => void) => {
  API.get('/thread/get_threads')
    .then(res => {
      callback && callback(res.data as Thread[])
    })
    .catch(err => errCallback && errCallback(err))
}

interface ChatThreadRequest {
  thread_id: number
  session_id: number
  document_ids: string[]
  user_id: 0
  offering: number
  is_private: boolean
  language: 'En' | 'Ar'
  query: string
  sender: 'user'
  filing: 'useruploaded'
}
export const _chatThread = (
  requestBody: ChatThreadRequest,
  callback?: (res: { response: string; path_to_s3?: string[] }) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.post(`/thread/chat?thread_id=${requestBody.thread_id}`, requestBody)
    .then(res => {
      callback && callback(res.data as { response: string; path_to_s3?: string[] })
    })
    .catch(err => errCallback && errCallback(err))
}
