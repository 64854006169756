import { useEffect, useRef, useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/store'
import { setLastChatBotMessage, setLoading } from '@redux/chatBot/chatBotSlice'
import EmptyState from './components/EmptyState'
import ChatBotInput from './components/ChatBotInput'
import ChatBotHeader from './components/ChatBotHeader'
import ChatBotMessageBubble from './components/ChatBotMessageBubble'
import { ChatBotMessageInterface, _getChatBotHistory, _sendChatBotMessage } from '@api/chatbot'
import { Link, useLocation } from 'react-router-dom'
import IconButton from '@coreComponents/IconButton'
import { BsArrowDown } from 'react-icons/bs'
import { toast } from 'core/helpers/toast'
import { _chatThread, _getThreads } from '@api/thread'
import { addMessageToSession, setSessions } from '@redux/sessions/sessionsSlice'

function UserReportsChatBot() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const offering = location.pathname.startsWith('/dashboard/public_filings') ? 1 : 2
  const chatBotStore = useAppSelector(state => state.chatBot)
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { sessions } = useAppSelector(state => state.sessions)

  // Scroll to bottom state and handler
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false)
  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current
    const isScrolledToBottom = scrollHeight - scrollTop <= clientHeight + 10 // Adjust threshold as needed

    setIsScrollButtonVisible(!isScrolledToBottom)
  }, [])

  const scrollToBottom = () => {
    setTimeout(() => {
      const container = chatContainerRef.current
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    }, 200)
  }

  // -- Sessions --
  const fetchSessions = () => {
    _getThreads(res => {
      dispatch(setSessions(res))
      scrollToBottom()
    })
  }

  useEffect(() => {
    fetchSessions()
  }, [])

  useEffect(() => {
    scrollToBottom()
    //
  }, [sessions])
  // --------------
  const sendMessage = (query: string) => {
    if (loading) return
    setLoading(true)

    dispatch(
      addMessageToSession({
        message: {
          query,
          user_id: 0,
          // offering: 2,
          typed: true,
          sender: 'user',
          session_id: sessions[sessions.length - 1]?.id
        },
        thread_id: sessions[sessions.length - 1]?.id
      })
    )
    _chatThread(
      {
        filing: 'useruploaded',
        is_private: true,
        language: 'En',
        query,
        user_id: 0,
        document_ids: sessions[sessions.length - 1].documents.map(doc => doc?.id),
        offering: 2,
        sender: 'user',
        session_id: sessions[sessions.length - 1]?.id,
        thread_id: sessions[sessions.length - 1]?.id
      },
      res => {
        setLoading(false)
        scrollToBottom()
        dispatch(
          addMessageToSession({
            message: {
              query: res.response,
              user_id: 0,
              plot_paths_to_s3: res.path_to_s3,
              offering: 2,
              sender: 'bot',
              session_id: sessions[sessions.length - 1]?.id
            },
            thread_id: sessions[sessions.length - 1]?.id
          })
        )
      },
      err => {
        setLoading(false)
        scrollToBottom()
      }
    )
    scrollToBottom()
  }

  useEffect(() => {
    const container = chatContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  // State for dot animation
  const [dotIndex, setDotIndex] = useState(0)
  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setDotIndex(prevIndex => (prevIndex + 1) % 3)
      }, 500)
      return () => clearInterval(intervalId)
    }
  }, [loading])
  // Dot animation styles
  const dotStyle = (index: number) => ({
    display: 'inline-block',
    width: '8px',
    height: '8px',
    margin: '0 2px',
    borderRadius: '50%',
    backgroundColor: 'white',
    opacity: dotIndex === index ? 1 : 0.5,
    transition: 'opacity 0.5s'
  })

  return (
    <div className='flex flex-col justify-between h-[100%] bg-[#1A1B1C]'>
      <ChatBotHeader />
      <div ref={chatContainerRef} className='flex-1 overflow-y-scroll p-[10px] scroll-smooth'>
        <div className='max-w-[650px] mx-auto overflow-y-hidden'>
          {/* {chatBotStore.messages?.length ? (
            chatBotStore.messages.map((message: ChatBotMessageInterface) => (
              <ChatBotMessageBubble key={message.id} message={message} />
            ))
          ) : (
            <EmptyState />
          )} */}

          {sessions.length > 0 ? (
            sessions.map((session, i) => (
              <div
                className={`${i === sessions.length - 1 ? '' : 'border-b-[1px] border-gray-600 pb-[20px] mb-[38px]'}`}
              >
                <div className='flex items-center gap-[10px] max-w-[85%] mb-[20px]'>
                  {session?.documents.map(
                    doc =>
                      doc !== null && (
                        <Link
                          to={`/dashboard/multi_reports/session/${session?.id}`}
                          key={doc?.id}
                          className='flex-1 text-truncate text-sm bg-secondary-blue bg-opacity-30 text-primary-blue px-[8px] py-[2.5px] rounded-[5px] max-w-[140px]'
                        >
                          {doc?.document_name}
                        </Link>
                      )
                  )}
                </div>

                {session.messages.length === 0 ? (
                  <div className='text-center h-[300px] flex-center text-gray-400'>
                    <EmptyState label='No Messages' />
                  </div>
                ) : (
                  session.messages.map(message => <ChatBotMessageBubble key={message?.id} message={message} />)
                )}
              </div>
            ))
          ) : (
            <EmptyState />
          )}

          {loading && (
            <div className='my-[20px] flex justify-center'>
              <div style={dotStyle(0)}></div>
              <div style={dotStyle(1)}></div>
              <div style={dotStyle(2)}></div>
            </div>
          )}
        </div>
      </div>

      <div className='w-full max-w-[660px] mx-auto relative'>
        {isScrollButtonVisible && (
          <IconButton
            onClick={scrollToBottom}
            className='absolute bottom-5 left-1/2 transform -translate-x-1/2 top-[-30px] rounded-full bg-secondary-blue h-fit'
            style={{ boxShadow: '0 3px 4px 0 #00000058' }}
          >
            <BsArrowDown color='white' size={13} strokeWidth={1} />
          </IconButton>
        )}
        <ChatBotInput onMessage={sendMessage} />
      </div>
    </div>
  )
}

export default UserReportsChatBot
