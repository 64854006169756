// ** Axios Imports
import axios from 'axios'
import { toast } from 'core/helpers/toast'

// ** Axios Parameters
const secIoBaseUrl = 'https://api.sec-api.io/'
const regularBaseURL = 'https://www.chizl-api.ai/'
const testingBaseURL = 'https://staging.chizl-api.ai/'

// ** Axios Instances
export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL
  // timeout: 9000000 // 5 minutes in milliseconds
})

export const SecIoAPI = axios.create({
  baseURL: secIoBaseUrl,
  headers: {
    Authorization: '942dd43c54be469054c83dbba5c49205c7153344a9bb74921a2eee83b4410a4d'
  },
  timeout: 9000000 // 5 minutes in milliseconds
})

// Request Interceptor
API.interceptors.request.use(
  async config => {
    const token = sessionStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Response Interceptor
API.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Redirect to /login if 401 error
      window.location.href = '/login' // or use history.push('/login') if using react-router's useHistory
      toast.error('Session expired, please login again')
    }
    return Promise.reject(error)
  }
)
