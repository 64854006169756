// ** React Imports
import React from 'react'

// ** Sidebar Imports
import { sidebarProps } from './sidebarProps'

function Sidebar({ ...rest }: sidebarProps) {
  return <div className={`w-[220px] bg-[#1B1C1D] h-full py-[16px] px-[10px] ${rest.className}`}>{rest.children}</div>
}

export default Sidebar
