import React from 'react'

function ChizlTextGradient({ ...props }: React.ComponentProps<'svg'>) {
  return (
    <svg width='93' height='18' viewBox='0 0 93 18' fill='none' {...props}>
      <defs>
        <linearGradient id='gradient' x1='50%' y1='0%' x2='50%' y2='100%'>
          <stop offset='0%' stopColor='#A1A1A1' /> {/* Gray 500 */}
          <stop offset='100%' stopColor='#101011' /> {/* Gray 900 */}
        </linearGradient>
      </defs>
      <path
        d='M0.589326 9.05855C0.589326 4.23816 4.63546 0.316914 9.45585 0.316914H13.3271C16.0745 0.316914 18.4722 1.61567 19.9958 3.26409L17.9227 5.41204C16.7489 4.11328 14.6759 3.38897 13.3521 3.38897H9.43087C6.75843 3.38897 3.66139 5.53692 3.66139 9.15846C3.66139 12.1056 6.05909 14.903 9.45585 14.903H13.3521C15.1504 14.903 16.7988 14.0288 17.9477 12.8799L20.0207 15.0528C18.5472 16.6263 16.0495 18 13.3771 18H9.53077C4.4856 18 0.589326 14.0288 0.589326 9.05855ZM26.3157 7.68487H38.9786V0.516721H42.1006V18H38.9786V10.8319H26.3157V18H23.1937V0.516721H26.3157V7.68487ZM46.3161 18V0.516721H49.4131V18H46.3161ZM53.0723 0.516721H71.9792V3.21414L58.4172 14.878H71.9792V18H53.0723V15.3775L66.6593 3.63874H53.0723V0.516721ZM78.5119 0.516721V14.878H92.4735V18H75.3899V0.516721H78.5119Z'
        fill='url(#gradient)'
        // fill={props.color ?? 'currentColor'}
      />
    </svg>
  )
}

export default ChizlTextGradient
