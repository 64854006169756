// ** Axios
import { AxiosError } from 'axios'

// ** API
import { API, SecIoAPI } from '..'

// ** Redux
import { store } from '@redux/store'
import { setPublicFilings } from '@redux/publicFilings/publicFilingsSlice'

// ** Interfaces
export interface Company {
  display: string
  id: string
}

export interface publicFilingDocumentInterface {
  type?: string
  title?: string
  date?: string
  filing_period?: string
  content?: string
  encoded_pdf: string
  document_url: string
  path_to_s3: string
  id: number | string
}
export interface PublicFilingsDataInterface {
  [key: string]: publicFilingDocumentInterface[]
}

/**
 * Get all the companies for public filings
 * @param query The search query
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _getPublicFilingCompanies = (
  query: string,
  callback?: (companies: Company[]) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/public_filings/companies?pattern=${query}`)
    .then(res => callback && callback(res.data as Company[]))
    .catch(err => errCallback && errCallback(err))
}

/**
 * Get all the public filings of a company by company Id
 * @param companyId The search query
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _getPublicFilingsByCompanyId = (
  companyId: string,
  callback?: (filingsData: PublicFilingsDataInterface) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/public_filings/sec/filings?company_id=${companyId}`)
    .then(res => {
      let publicFilingsData: PublicFilingsDataInterface = res.data

      // Filing Sections
      const sections = ['Financials', 'News', 'Prospectuses and Registrations', 'Proxies', 'Ownership', 'Other']

      // Add the filings according to their sections
      sections.forEach((section: string) => {
        if (!publicFilingsData[section]) {
          publicFilingsData[section] = []
        }
      })
      callback && callback(publicFilingsData)
      store.dispatch(setPublicFilings(publicFilingsData))
    })
    .catch(err => errCallback && errCallback(err))
}

/**
 * Get a public filing in PDF format by passing the htm url
 * @param url The htm public filing URL
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _getPublicFilingPDF = (
  url: string,
  callback?: (data: ArrayBuffer) => void,
  errCallback?: (error: AxiosError) => void
) => {
  SecIoAPI.get(`/filing-reader?&type=pdf&url=${url}`, { responseType: 'arraybuffer' })
    .then(res => {
      callback && callback(res.data)
    })
    .catch(err => errCallback && errCallback(err))
}

/**
 * Get a public filing document by id
 * @param id The id of the filing
 * @param callback Function to handle successful response
 * @param errCallback Function to handle error response
 */
export const _getPublicFilingById = (
  id: string,
  callback?: (data: publicFilingDocumentInterface) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/public_filings/sec/filing?filing_id=${id}`)
    .then(res => {
      callback && callback(res.data)
    })
    .catch(err => errCallback && errCallback(err))
}

interface GccCompany {
  [year: string]: {
    Q1?: {
      Ar?: string // Link to PDF document for Q1 in Arabic
      En?: string // Link to PDF document for Q1 in English
      doc_id?: string
      is_embedded?: boolean
    }
    Q2?: {
      Ar?: string // Link to PDF document for Q2 in Arabic
      En?: string // Link to PDF document for Q2 in English
      doc_id?: string
      is_embedded?: boolean
    }
    Q3?: {
      Ar?: string // Link to PDF document for Q3 in Arabic
      En?: string // Link to PDF document for Q3 in English
      doc_id?: string
      is_embedded?: boolean
    }
    Q4?: {
      Ar?: string // Link to PDF document for Q4 in Arabic
      En?: string // Link to PDF document for Q4 in English
      doc_id?: string
      is_embedded?: boolean
    }
  }
}

interface IndustryFiling {
  [industry: string]: {
    [bankName: string]: GccCompany
  }
}

export interface GCCFilingResponse {
  [exchange: string]: {
    [country: string]: IndustryFiling
  }
}

export const _getGCCFilingByExchange = (
  {
    country,
    exchange
  }: { country: 'UAE' | 'Saudi Arabia' | 'Kuwait' | 'Qatar' | 'Bahrain' | string; exchange: string },
  callback?: (data: GCCFilingResponse) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/public_filings/gcc/${exchange}/filings?country=${country}`)
    .then(res => {
      callback && callback(res.data)
    })
    .catch(err => errCallback && errCallback(err))
}

export const _getGccExchangeByCountry = (
  country: 'UAE' | 'Saudi Arabia' | 'Kuwait' | 'Qatar' | 'Bahrain' | string,
  callback?: (data: string[]) => void,
  errCallback?: (error: AxiosError) => void
) => {
  API.get(`/public_filings/gcc/${country}/exchanges`)
    .then(res => {
      callback && callback(res.data)
    })
    .catch(err => errCallback && errCallback(err))
}
