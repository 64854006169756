import { capitalize } from '@mui/material'
import { useAppSelector } from '@redux/store'
import { regions } from 'core/constants'
import React from 'react'
import { Link } from 'react-router-dom'

function SelectFilingRegion() {
  // ** States
  const userData = useAppSelector(state => state.auth.user)

  return (
    <div className='p-md'>
      <h1 className='text-6xl font-semibold mb-md text-primary-blue'>Hello, {capitalize(userData?.firstname ?? '')}</h1>
      <span className='text-[#656565] text-4xl'>Which exchange would you like to start with?</span>

      <div className='grid gap-[30px] mt-xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
        {regions.map(region => (
          <Link
            key={region.code}
            className='bg-[#2C2C2C] p-md rounded-[20px]'
            to={`/dashboard/public_filings/${region.code}`}
          >
            <div className='flex items-center gap-2 mb-2'>
              <img
                src={`https://flagcdn.com/w160/${region.code}.png`}
                srcSet={`https://flagcdn.com/w320/${region.code}.png 2x`}
                width='20'
                alt={region.name}
                className='w-[61px] h-[36px] rounded-md'
              />
              <h2 className='text-3xl font-semibold'>{region.name}</h2>
            </div>

            <div className='text-[#656565] font-semibold text-xl text-right'>
              {region.exchanges.map(
                (ex, index) => `${ex.toUpperCase()}${index !== region.exchanges.length - 1 ? ' | ' : ''}`
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SelectFilingRegion
