// ** React Imports
import React from 'react'

// ** Props Imports
import { sidebarButtonProps } from './sidebarButtonProps'

// ** Helpers Imports
import { getTextColorClassName } from '../../../helpers/tailwind/getTextColorClassName'
import { getBackgroundColorClassName } from '../../../helpers/tailwind/getBackgroundColorClassName'

function SidebarButton({
  color = 'secondary-blue',
  textColor,
  selectedTextColor = 'chizl-black',
  isSelected = false,
  icon,
  disabled = false,
  ...rest
}: sidebarButtonProps) {
  // ** Styling Params
  const bgColorClassName = getBackgroundColorClassName(color)
  const textColorClassName = getTextColorClassName(textColor ? textColor : color)
  const selectedTextColorClassName = getTextColorClassName(selectedTextColor)

  return (
    <button
      disabled={disabled}
      className={`flex items-center gap-[10px] w-full text-left rounded-[5px] px-[5px] py-[3px] font-semibold mb-[0px] ${isSelected ? selectedTextColorClassName : textColorClassName} ${isSelected && bgColorClassName} ${disabled && 'opacity-50'} ${rest.className}`}
    >
      {icon}
      {rest.children}
    </button>
  )
}

export default SidebarButton
