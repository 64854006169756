// ** React Imports
import React, { useEffect } from 'react'

// ** Redux Imports
import { useAppDispatch, useAppSelector } from '@redux/store'
import { setOffering } from '@redux/chatBot/chatBotSlice'
import { setFetchLoading, setResearchReports, setStatuses } from '@redux/researchReports/researchReportsSlice'

// ** Config Imports
import { dashboardConfig } from '../../dashboardConfig'

// ** API Imports
import { _getDocumentStatuses, _getUserDocuments } from '@api/research_reports'

// ** Component Imports
import ResearchReportsBody from './components/ResearchReportsBody'
import ResearchReportsHeader from './components/ResearchReportsHeader'
import ResearchReportsSidebar from './components/ResearchReportsSidebar'
import SidebarPageWrapper from '@coreComponents/sidebar-components/SidebarPageWrapper'

function ResearchReports() {
  // ** Hooks
  const dispatch = useAppDispatch()

  // ** States
  const documents = useAppSelector(state => state.researchReports.researchReports)

  const fetchDocuments = () => {
    dispatch(setOffering(2))
    // if (!documents || (documents && documents.length === 0)) {
    //   dispatch(setFetchLoading(true))
    //   _getResearchReports(
    //     documents => {
    //       _getPrivateDocuments(
    //         privateDocs => {
    //           const updatedPrivateDocs = privateDocs.map(doc => ({
    //             ...doc,
    //             isPrivate: true
    //           }))
    //           dispatch(setFetchLoading(false))
    //           dispatch(setResearchReports([...documents, ...updatedPrivateDocs]))
    //         },
    //         err => {
    //           dispatch(setFetchLoading(false))
    //         }
    //       )
    //     },
    //     err => {
    //       dispatch(setFetchLoading(false))
    //     }
    //   )
    // }
    if (!documents || (documents && documents.length === 0)) {
      dispatch(setFetchLoading(true))
      _getUserDocuments(
        documents => {
          dispatch(setFetchLoading(false))
          dispatch(setResearchReports(documents))
        },
        err => {
          dispatch(setFetchLoading(false))
        }
      )
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [dispatch])

  useEffect(() => {
    const fetchDocumentStatuses = () => {
      _getDocumentStatuses((data: any) => {
        console.log(data)
        dispatch(setStatuses(data))
      })
    }

    // Fetch immediately on mount
    fetchDocumentStatuses()

    // Set up interval to fetch every 12.5 seconds
    const intervalId = setInterval(fetchDocumentStatuses, 12500)

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId)
  }, [dispatch])

  return (
    <SidebarPageWrapper
      renderSidebar={<ResearchReportsSidebar />}
      // className={`overflow-y-auto ${dashboardConfig.paddingClassName}`}
      className='flex flex-col'
    >
      <div className={`${dashboardConfig.paddingClassName} pb-0`}>
        <ResearchReportsHeader />
      </div>
      <ResearchReportsBody />
    </SidebarPageWrapper>
  )
}

export default ResearchReports
