import { ChatBotMessageInterface } from '@api/chatbot'
import { Thread } from '@api/thread'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  sessions: Thread[]
}

const initialState: InitialState = {
  sessions: []
}

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setSessions(state, action: PayloadAction<Thread[]>) {
      state.sessions = action.payload
    },
    addSession(state, action: PayloadAction<Thread>) {
      state.sessions = [...state.sessions, action.payload]
    },
    addMessageToSession(state, action: PayloadAction<{ thread_id: number; message: ChatBotMessageInterface }>) {
      const threadToUpdate = state.sessions.find(el => el.id === action.payload.thread_id)
      if (!threadToUpdate) return

      const updatedMessages = [...threadToUpdate.messages, action.payload.message]
      threadToUpdate.messages = updatedMessages
      state.sessions = state.sessions.map(session => (session.id === threadToUpdate.id ? threadToUpdate : session))
    },
    clearSessions(state) {
      state.sessions = []
    }
  }
})

export const { setSessions, addSession, clearSessions, addMessageToSession } = sessionsSlice.actions
export default sessionsSlice.reducer
