import React from 'react'

function Loader({ className }: { className?: string }) {
  return (
    <div
      className={`w-[17px] h-[17px] border-[3px] border-t-[3px] border-gray-200 border-t-blue-500 rounded-full animate-spin ${className}`}
    ></div>
  )
}

export default Loader
