import React, { useEffect, useState } from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@redux/store'

import Layout from './core/layout'
import Home from './pages/Home'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import DashboardLayout from './pages/DashboadLayout'
import PublicFilings from './pages/DashboadLayout/views/PublicFilings'
import ViewPublicFiling from './pages/DashboadLayout/views/PublicFilings/ViewPublicFiling'
import ResearchReports from './pages/DashboadLayout/views/ResearchReports'
import ViewResearchReport from './pages/DashboadLayout/views/ResearchReports/ViewResearchReport'
import DashboardComingSoon from './pages/DashboadLayout/components/DashboardComingSoon'
import SelectFilingRegion from './pages/DashboadLayout/views/SelectFilingRegion'
import { Toaster } from 'react-hot-toast'
import 'keen-slider/keen-slider.min.css'
import { checkInitialThemeOnLoad } from './core/theme/checkInitialThemeOnLoad'
import { AuthProvider } from 'core/auth'
import ProtectedRoute from '@coreComponents/ProtectedRoute'
import General from 'pages/DashboadLayout/views/General'
import LandingV2 from 'pages/LandingV2'
import Chizl from '@icons/Chizl'
import ViewSession from 'pages/DashboadLayout/views/ResearchReports/ViewSession'

function App() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const initializeApp = async () => {
      await checkInitialThemeOnLoad()
      // Perform any other initial setup if needed
      setIsLoading(false) // Set loading to false when done
    }
    initializeApp()
  }, [])

  const Loader = () => (
    <div className='bg-white h-[100vh] flex-center'>
      <Chizl className='text-black' width='90%' height='100px' />
    </div>
  )

  if (isLoading) {
    return <Loader /> // Show the loader while loading
  }

  return (
    <Provider store={store}>
      <Toaster />
      <Router>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path='/landing/v1' element={<Home />} />
              <Route path='/' element={<LandingV2 />} />
              <Route path='/login' element={<Login />} />
              {/* <Route path='/register' element={<SignUp />} /> */}
              <Route
                path='/dashboard/*'
                element={
                  <ProtectedRoute>
                    <DashboardLayout />
                  </ProtectedRoute>
                }
              >
                {/* Default route for /dashboard */}
                <Route index element={<Navigate to='public_filings' replace />} />

                {/* Public Filings Routes */}
                <Route path='public_filings/*'>
                  {/* Default route for /dashboard/public_filings */}
                  <Route index element={<Navigate to='regions' replace />} />
                  <Route path='regions' element={<SelectFilingRegion />} />
                  <Route path=':region' element={<PublicFilings />} />
                  <Route path=':region/:documentId' element={<ViewPublicFiling />} />
                </Route>

                {/* Other Routes */}
                <Route path='general' element={<General />} />
                <Route path='multi_reports' element={<ResearchReports />} />
                <Route path='multi_reports/session/:sessionId' element={<ViewSession />} />
                <Route path='multi_reports/:documentId' element={<ViewResearchReport />} />
                <Route path='transcriptions' element={<DashboardComingSoon />} />
              </Route>
            </Routes>
          </Layout>
        </AuthProvider>
      </Router>
    </Provider>
  )
}

export default App
